import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';
import RectangleLink from '../../components/RectangleLink';
import Clarification from '../../components/Clarification';
import enMessages from '../../i18n/legal/infoRates/en';
import ruMessages from '../../i18n/legal/infoRates/ru';
import enMainMessages from '../../i18n/LegalInformation/en';
import ruMainMessages from '../../i18n/LegalInformation/ru';
import { isBrowser } from '../../utils/isBrowser';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const messagesMain = {
  en: enMainMessages,
  ru: ruMainMessages,
};

const InformationOnInterestRatesPage = (props) => {
  const { pathContext: { locale } } = props;

  const headerStyleNumb = '10';
  const section = 'infoRates';

  if (isBrowser && locale === 'en') {
    window.location.replace('/en/legal/requisite');
  }

  return (
    <TemplateLegalPage
      headerStyleNumb={headerStyleNumb}
      locale={locale}
      section={section}
      messages={messages[locale]}
      messagesMain={messagesMain[locale]}
    >
      {messages[locale]?.content?.map((item) => {
        return (
          <React.Fragment key={item.link}>
            {item.title ? (
              <RectangleLink
                label={item.label}
                link={item.link}
                target="_blank"
                title={item.title}
              />
            ) : null}

            {item.clarification ? (
              <Clarification
                label={item.clarification.label}
                text={item.clarification.text}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </TemplateLegalPage>
  );
};

export default InformationOnInterestRatesPage;
