export default {
    seo: {
      title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами | Банк 131',
      description: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами'
    },
    title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами',
    content: [
      {
        label: 'Дата размещения 13.03.2025',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за февраль 2025.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_February_2025.pdf',
      },
      {
        label: 'Дата размещения 12.02.2025',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за январь 2025.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_January_2025.pdf',
      },
      {
        label: 'Дата размещения 22.01.2025',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за декабрь 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_December_2024.pdf',
      },
      {
        label: 'Дата размещения 12.12.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за ноябрь 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_November_2024.pdf',
      },
      {
        label: 'Дата размещения 14.11.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за октябрь 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_October_2024.pdf',
      },
      {
        label: 'Дата размещения 15.10.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за сентябрь 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_September_2024.pdf',
      },
      {
        label: 'Дата размещения 12.09.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за август 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_August_2024.pdf',
      },
      {
        label: 'Дата размещения 13.08.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за июль 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_July_2024.pdf',
      },
      {
        label: 'Дата размещения 11.07.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за июнь 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_June_2024.pdf',
      },
      {
        label: 'Дата размещения 11.06.2024',
        title: 'Информация о процентных ставках по договорам банковского вклада с физическими лицами за май 2024.pdf',
        link: '/legal/ru/information_on_interest_rates_on_bank_deposit_agreements_with_individuals_for_May_2024.pdf',
      },
    ],
}